/* eslint-disable camelcase */
import { call, put, select } from 'redux-saga/effects'
import { leadsActions } from 'redux/reducers/ui/leads'
import { errorsActions } from 'redux/reducers/errors'
import _isObject from 'lodash/isPlainObject'
import { getLeads } from 'api'
import _includes from 'lodash/includes'
import { adminRole } from 'redux/constants'

export default function* leadsWorker({ payload: { query } }: {
    payload: {
        query: {
            offset: number;
            limit: number;
            bayer?: string;
            status?: string;
            offer?: string;
            funnel?: string;
            country?: string;
            source?: string;
            pp?: string;
            from?: string;
            to?: string;
        },
    },
}) {
  try {
    // @ts-ignore
    const user = yield select((state) => state.auth.user)

    if (!user) {
      return
    }

    const {
      leads, pages, total,
    } = yield call(getLeads, {
      ...query,
      ...(user.roles && _includes(user.roles, adminRole) ? {} : { bayer: user.name || 'noneempty' }),
    })

    yield put(leadsActions.leadsUpdate({
      data: leads,
      pages,
      total,
    }))
  } catch (error) {
    // @ts-ignore
    const err = _isObject(error) ? error.message : error
    yield put(errorsActions.genericError({
      message: err || 'apiNotifications.somethingWentWrong',
    }))
  } finally {
    yield put(leadsActions.changeLoading(false))
  }
}
