import { call, put } from 'redux-saga/effects'
import Debug from 'debug'

import { alertsActions } from 'redux/reducers/alerts'

const debug = Debug('Platform:rx:s:logout')

export default function* logoutWorker() {
  try {
    yield put(alertsActions.generateAlerts({ message: 'You have been logged out' }))
  } catch (e) {
    debug('Error while getting general stats data: %s', e)
  }
}
