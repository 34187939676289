import { call, put } from 'redux-saga/effects'
import { authActions } from 'redux/reducers/auth'
import { errorsActions } from 'redux/reducers/errors'
import _isObject from 'lodash/isPlainObject'

import { setAccessToken } from 'utils/accessToken'
import { login } from 'api'
import { setRefreshToken } from 'utils/refreshToken'

export default function* singinWorker({ payload: { credentials, callback } }: {
  payload: {
    credentials: {
      email: string,
      password: string,
      dontRemember: boolean,
    },
    callback: (isSuccess: boolean) => void,
  },
}) {
  try {
    const {
      user, accessToken, refreshToken,
    } = yield call(login, credentials)

    yield put(authActions.loginSuccessful(user))
    yield call(setAccessToken, accessToken)
    yield call(setRefreshToken, refreshToken)
    callback(true)
  } catch (error) {
    // @ts-ignore
    const err = _isObject(error) ? error.message : error
    yield put(errorsActions.genericError({
      message: err || 'apiNotifications.somethingWentWrong',
    }))
    callback(false)
  } finally {
    yield put(authActions.finishLoading())
  }
}
