/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StateType } from 'redux/store'
import { setTheme } from 'api'
import { setItem } from 'utils/localstorage'
import { authActions } from 'redux/reducers/auth'
import _map from 'lodash/map'

const themesList = [
  'light',
  'dark',
  'cupcake',
  'bumblebee',
  'emerald',
  'corporate',
  'synthwave',
  'retro',
  'cyberpunk',
  'valentine',
  'halloween',
  'garden',
  'forest',
  'aqua',
  'lofi',
  'pastel',
  'fantasy',
  'wireframe',
  'black',
  'luxury',
  'dracula',
  'cmyk',
  'autumn',
  'business',
  'acid',
  'lemonade',
  'night',
  'coffee',
  'winter',
  'dim',
  'nord',
  'sunset',
]

const ThemeChanger = () => {
  const dispatch = useDispatch()
  const { theme: themeType, id } = useSelector((state: StateType) => state.auth.user)
  const [theme, setThemeState] = useState(themeType)

  return (
    <div>
      <div title='Change Theme' className='dropdown dropdown-end'>
        <div tabIndex={0} role='button' className='btn btn-ghost'>
          <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' className='h-5 w-5 stroke-current md:hidden'>
            <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01' />
          </svg>
          <span className='hidden font-normal md:inline'>Theme</span>
        </div>
        <div tabIndex={0} className='dropdown-content bg-base-200 text-base-content rounded-box top-px h-[28.6rem] max-h-[calc(100vh-10rem)] w-56 overflow-y-auto border border-white/5 shadow-2xl outline outline-1 outline-black/5 mt-16'>
          {_map(themesList, (themeName) => (
            <div key={themeName} className='grid grid-cols-1'>
              <button
                tabIndex={0}
                type='button'
                onClick={() => {
                  setThemeState(themeName)
                  setTheme(id, themeName)
                  setItem('theme', themeName)
                  dispatch(authActions.setTheme(themeName))
                }}
                className='outline-base-content text-start outline-offset-4 hover:bg-base-300 hover:text-base-content p-2 rounded'
              >
                {themeName}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ThemeChanger
