import React, {
  useEffect, lazy, Suspense,
} from 'react'
import { Switch, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
// @ts-ignore
import { useAlert } from '@blaumaus/react-alert'
import 'dayjs/locale/ru'
import 'dayjs/locale/uk'

import Header from 'components/Header'
import LeftSidebar from 'components/LeftSidebar'

import _includes from 'lodash/includes'

import { getAccessToken } from 'utils/accessToken'
import { authActions } from 'redux/reducers/auth'
import sagaActions from 'redux/sagas/actions'
import { errorsActions } from 'redux/reducers/errors'
import { alertsActions } from 'redux/reducers/alerts'
import { StateType, useAppDispatch } from 'redux/store'
import routeLoader from 'utils/chunkreload'
import routes from 'routes'
import { getRefreshToken } from 'utils/refreshToken'
import { authMe } from './api'
import { adminRole, superAdminRole } from './redux/constants'

const MainPage = lazy(() => routeLoader(() => import('pages/MainPage')))
const SignIn = lazy(() => routeLoader(() => import('pages/Auth/Signin')))
const NotFound = lazy(() => routeLoader(() => import('pages/NotFound')))
const Dashboard = lazy(() => routeLoader(() => import('pages/Dashboard/Dashboard')))
const AffiliateNetworks = lazy(() => routeLoader(() => import('pages/AffiliateNetworks')))
const Stats = lazy(() => routeLoader(() => import('pages/Stats')))
const Offers = lazy(() => routeLoader(() => import('pages/Offers')))
const Campaign = lazy(() => routeLoader(() => import('pages/Campaign')))
const FinanceAdmin = lazy(() => routeLoader(() => import('pages/Finance/FinanceAdmin')))
const FinanceBuyer = lazy(() => routeLoader(() => import('pages/Finance/FinanceBuyer')))
const Faq = lazy(() => routeLoader(() => import('pages/Faq')))
const ManageUsers = lazy(() => routeLoader(() => import('pages/Users')))
const Pwa = lazy(() => routeLoader(() => import('pages/Pwa')))
const DeepLinking = lazy(() => routeLoader(() => import('pages/DeepLinking')))

const App = () => {
  const dispatch = useAppDispatch()
  const alert = useAlert()
  const {
    loading, authenticated, user,
  } = useSelector((state: StateType) => state.auth)
  const { error } = useSelector((state: StateType) => state.errors)
  const { message, type } = useSelector((state: StateType) => state.alerts)
  // const themeType = useSelector(state => state.ui.theme.type)
  const accessToken = getAccessToken()
  const refreshToken = getRefreshToken()

  useEffect(() => {
    const loaderEl = document.getElementById('loader')

    if (loaderEl?.outerHTML) {
      loaderEl.classList.add('available')
    }
  }, [])

  useEffect(() => {
    (async () => {
      if ((accessToken && refreshToken) && !authenticated) {
        try {
          const me = await authMe()
          dispatch(authActions.loginSuccessful(me))
          dispatch(authActions.finishLoading())
        } catch (e) {
          dispatch(authActions.logout())
          dispatch(sagaActions.logout())
        }
      }
    })()
  }, [authenticated]) // eslint-disable-line

  useEffect(() => {
    if (error) {
      alert.error(error.toString(), {
        onClose: () => {
          dispatch(errorsActions.clearErrors())
        },
      })
    }
  }, [error]) // eslint-disable-line

  useEffect(() => {
    if (message && type) {
      alert.show(message.toString(), {
        type,
        onClose: () => {
          dispatch(alertsActions.clearAlerts())
        },
      })
    }
  }, [message, type]) // eslint-disable-line
  return (
    <>
      {(!accessToken || !loading) && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <Suspense fallback={<></>}>
          {/* @ts-ignore */}
          <div className='drawer lg:drawer-open min-h-screen'>
            <input id='left-sidebar-drawer' type='checkbox' className='drawer-toggle' />
            <div className='drawer-content flex flex-col overflow-hidden'>
              {authenticated && (
                <Header
                  authenticated={authenticated}
                  user={user}
                />
              )}
              <Switch>
                <Route path={routes.signin} component={SignIn} exact />
                <Route path={routes.main} component={MainPage} exact />
                <main className='flex-1 overflow-y-auto pt-8 px-6 bg-base-200'>
                  <Route path={routes.dashboard} component={Dashboard} exact />
                  {_includes(user?.roles, adminRole) && (
                    <Route path={routes.affiliateNetworks} component={AffiliateNetworks} exact />
                  )}
                  {_includes(user?.roles, superAdminRole) && (
                    <Route path={routes.users} component={ManageUsers} exact />
                  )}
                  <Route path={routes.stats} component={Stats} exact />
                  <Route path={routes.offers} component={Offers} exact />
                  <Route path={routes.campaign} component={Campaign} exact />
                  <Route path={routes.pwa} component={Pwa} exact />
                  <Route path={routes.deepLinking} component={DeepLinking} exact />
                  {_includes(user?.roles, adminRole) ? (
                    <Route path={routes.finance} component={FinanceAdmin} exact />
                  ) : (
                    <Route path={routes.finance} component={FinanceBuyer} exact />
                  )}
                  <Route path={routes.faq} component={Faq} exact />
                </main>
                <Route path='*' component={NotFound} />
              </Switch>
            </div>
            {authenticated && (
              <LeftSidebar />
            )}
          </div>
        </Suspense>
      )}
      <div className='hidden' />
    </>
  )
}

export default App
