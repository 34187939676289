/* eslint-disable no-unused-vars */
import types from 'redux/sagas/actions/types'
import { getRefreshToken, removeRefreshToken } from 'utils/refreshToken'
import { logoutApi } from 'api'
import { removeAccessToken } from 'utils/accessToken'

const logout = () => {
  // logoutApi(refreshToken)
  removeAccessToken()
  removeRefreshToken()

  return {
    type: types.LOGOUT,
    payload: {},
  }
}

const loginAsync = (payload: any, callback: () => void) => ({
  type: types.LOGIN_ASYNC,
  payload: {
    credentials: payload,
    callback,
  },
})

const getLeads = (payload: {
  query: {
    offset: number;
    limit: number;
    bayer?: string;
    status?: string;
    offer?: string;
    funnel?: string;
    country?: string;
    source?: string;
    pp?: string;
    from?: string;
    to?: string;
  },
}) => ({
  type: types.GET_LEADS,
  payload,
})

const sagaActions = {
  logout,
  loginAsync,
  getLeads,
}

export default sagaActions
