import { combineReducers, configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { useDispatch } from 'react-redux'
import rootSaga from '../sagas'
import authSlice from '../reducers/auth'
import errorsSlice from '../reducers/errors'
import alertsSlice from '../reducers/alerts'
import leadsSlice from '../reducers/ui/leads'

const rootReducer = combineReducers({
  auth: authSlice,
  errors: errorsSlice,
  alerts: alertsSlice,
  ui: combineReducers({
    leads: leadsSlice,
  }),
})

const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
  reducer: rootReducer,
  middleware: [sagaMiddleware],
  devTools: true,
})

sagaMiddleware.run(rootSaga)

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export type StateType = ReturnType<typeof store.getState>
