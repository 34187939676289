import { takeEvery, fork } from 'redux-saga/effects'
import sagaTypes from '../actions/types'

import initialise from './initialise'
import logout from './logout'

function* mainUISaga() {
  yield fork(initialise)
  // @ts-ignore
  yield takeEvery(sagaTypes.LOGOUT, logout)
}

export default mainUISaga
