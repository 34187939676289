const routeLoader = <T>(factory: () => Promise<T>, maxRetry = 3): Promise<T> => {
  return new Promise((resolve, reject) => {
    factory()
      .then(resolve)
      .catch((error) => {
        if (maxRetry === 0) {
          reject(error)
          window.location.reload()
          return
        }
        routeLoader(factory, --maxRetry).then(resolve, reject)
      })
  })
}

export default routeLoader
