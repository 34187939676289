import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { store } from 'redux/store'
import { Provider } from 'react-redux'
// @ts-ignore
import { transitions, positions, Provider as AlertProvider } from '@blaumaus/react-alert'
import { getAccessToken, removeAccessToken } from 'utils/accessToken'
import { getRefreshToken } from 'utils/refreshToken'

import CrashHandler from 'pages/CrashHandler'
import AlertTemplate from 'ui/Alert'
import App from './App'
import './index.css'

const options = {
  position: positions.BOTTOM_RIGHT,
  timeout: 8000,
  offset: '30px',
  transition: transitions.SCALE,
}

const removeObsoleteAuthTokens = () => {
  const accessToken = getAccessToken()
  const refreshToken = getRefreshToken()

  if (accessToken && !refreshToken) {
    removeAccessToken()
  }
}

removeObsoleteAuthTokens()

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <CrashHandler>
      <AlertProvider template={AlertTemplate} {...options}>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </AlertProvider>
    </CrashHandler>
  </React.StrictMode>,
)
