/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ILeads {
    id: number;
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    country: string;
    ip: string;
    custom1: string;
    custom2: string;
    custom3: string;
    custom4: string;
    offer: string;
    funnel: string;
    source: string;
    pp: string;
    bayer: string;
    status: string;
    created_at: Date;
    updated_at: Date;
}

interface IInitialState {
    leads: ILeads[];
    loading?: boolean;
    total?: number;
    pages?: number;
}

const initialState: IInitialState = {
  leads: [],
  total: 0,
  pages: 0,
  loading: true,
}

const leadsUpdate = (state: IInitialState, { payload }: PayloadAction<{ data: ILeads[], pages: number, total: number }>) => {
  state.leads = payload.data
  state.pages = payload.pages
  state.total = payload.total
}

const leadsSlice = createSlice({
  name: 'leads',
  initialState,
  reducers: {
    leadsUpdate,
    changeLoading: (state: IInitialState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },
  },
})

export const leadsActions = leadsSlice.actions
export default leadsSlice.reducer
