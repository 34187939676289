/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUser } from '../models/IUser'

interface IAuthState {
  user: IUser
  redirectPath: string | null
  authenticated: boolean
  loading: boolean
}

const initialState: IAuthState = {
  user: {} as IUser,
  redirectPath: null,
  authenticated: false,
  loading: true,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    finishLoading: (state) => {
      state.loading = false
    },
    loginSuccessful: (state, { payload }: PayloadAction<IUser>) => {
      state.user = payload
      const html = document.querySelector('html')
      html?.setAttribute('data-theme', payload.theme)
      state.authenticated = true
    },
    logout: (state) => {
      state.user = {} as IUser
      state.authenticated = false
    },
    setTheme: (state, { payload }: PayloadAction<string>) => {
      const html = document.querySelector('html')
      html?.setAttribute('data-theme', payload)
      state.user.theme = payload
    },
  },
})

export const authActions = authSlice.actions

export default authSlice.reducer
