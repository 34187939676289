import { takeLatest, all, call } from 'redux-saga/effects'
import signIn from 'redux/sagas/auth/workers/signin'
import logout from 'redux/sagas/auth/workers/logout'
import leadsWorker from '../workers/getLeads'
import sagaTypes from '../../actions/types'

function* watchLogin() {
  // @ts-ignore
  yield takeLatest(sagaTypes.LOGIN_ASYNC, signIn)
}

function* watchLogout() {
  // @ts-ignore
  yield takeLatest(sagaTypes.LOGOUT, logout)
}

function* watchGetLeads() {
  // @ts-ignore
  yield takeLatest(sagaTypes.GET_LEADS, leadsWorker)
}

export default function* watchAuth() {
  yield all([
    call(watchLogin),
    call(watchLogout),
    call(watchGetLeads),
  ])
}
