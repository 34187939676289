/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Bars3Icon from '@heroicons/react/24/outline/Bars3Icon'
import _includes from 'lodash/includes'

import { useHistory, Link, useLocation } from 'react-router-dom'
import sagaActions from 'redux/sagas/actions'
import { authActions } from 'redux/reducers/auth'
import routes from 'routes'
import ThemeChanger from '../ThemeChanger'

const nameToRoutes: {
  [key: string]: string,
} = {
  [routes.main]: 'Main',
  [routes.signin]: 'Login',
  [routes.dashboard]: 'Dashboard',
  [routes.affiliateNetworks]: 'Affiliate Networks',
  [routes.rainmaker]: 'Rainmaker Affiliate Network',
  [routes.velads]: 'Velads Affiliate Network',
  [routes.stats]: 'Stats',
  [routes.offers]: 'Offers',
  [routes.finance]: 'Finance',
  [routes.campaign]: 'Stats UAC',
  [routes.deepLinking]: 'Naming/DeepLink',
  default: '',
}

function Header({ user }: any) {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const pageTitle = _includes(routes, location.pathname) ? nameToRoutes[location.pathname] : nameToRoutes.default

  function logoutUser() {
    dispatch(sagaActions.logout())
    dispatch(authActions.logout())
    history.push('/login')
  }

  return (
    <div className='navbar flex justify-between bg-base-100  z-10 shadow-md relative'>
      <div className=''>
        <label htmlFor='left-sidebar-drawer' className='btn btn-primary drawer-button lg:hidden'>
          <Bars3Icon className='h-5 inline-block w-5' />
        </label>
        <h1 className='text-2xl font-semibold ml-2'>{pageTitle}</h1>
      </div>
      <div className='order-last'>
        <ThemeChanger />
        <div className='dropdown dropdown-end'>
          <label tabIndex={0} className='btn btn-ghost'>
            <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth='1.5' stroke='currentColor' className='w-6 h-6'>
              <path strokeLinecap='round' strokeLinejoin='round' d='M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z' />
            </svg>
          </label>
          <ul tabIndex={0} className='menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52 px-4'>
            {user?.email ? (
              <>
                <li className='pb-2 mb-2 border-b-2 border-base-200 max-w-[200px] overflow-scroll'>
                  <span>{user.email || 'user'}</span>
                </li>
                <li className='pb-2 mb-2 border-b-2 border-base-200'><Link className='cursor-pointer' to={routes.faq}>Faq</Link></li>
                <li className='cursor-pointer pb-2' onClick={logoutUser}><button type='button'>Logout</button></li>
              </>
            ) : (
              <li className='cursor-pointer'>
                <Link to={routes.signin}>Login</Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Header
