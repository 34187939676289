import { put, call } from 'redux-saga/effects'
import Debug from 'debug'

import { getAccessToken } from 'utils/accessToken'
import { getRefreshToken } from 'utils/refreshToken'
import sagaActions from '../actions'

const debug = Debug('Platform:rx:s:initialise')

export default function* initialise() {
  try {
    const token: string = yield call(getAccessToken)
    const refreshToken: string = yield call(getRefreshToken)

    if (token && refreshToken) {
      yield put(sagaActions.getLeads({
        query: {
          offset: 0,
          limit: 10,
        },
      }))
    }
  } catch (e) {
    debug('An error occured whilst initialising: %s', e)
  }
}
